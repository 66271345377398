<template>
  <div>
    <NavBar
    style="max-height: 50px; margin-bottom: 10px"
    :username="currentUserName"
    :pageName="pageName"
    ></NavBar>

    <v-app>
      <!-- dialog for edit benefit Start -->
      <div>
        <v-row justify="center">
          <v-dialog v-model="editBenefitModal" persistent>
            <v-card>
              <div class="closeBtnDiv">
                <v-btn icon @click="editBenefitPopupClose">
                  <img src="@/assets/popupClose.svg" >
                </v-btn>
              </div>
              <div class="benefitsRow" >
                <div class="benefitsIndividual">
                  <div>
                    <p>Package Scope</p>
                  </div>
                  <div>
                    <v-select
                      solo
                      v-model="editBenefitObject.package_type"
                      :items="listOfPackageScope"
                      style="width: 250px;"
                      >
                    </v-select>
                  </div>
                </div>
                <div class="benefitsIndividual">
                  <div>
                    <p>Applicable On</p>
                  </div>
                  <div>
                    <v-select
                      solo
                      multiple
                      v-model="editBenefitObject.applicable_list"
                      v-if="editBenefitObject.package_type == 'Doctors'"
                      :items="listOfDoctorsAvailable"
                      item-text="doctor_name"
                      item-value="_id"
                      return-object
                      :disabled="!editBenefitObject.package_type"
                      >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @click="toggleEditDoctor"
                          >
                          <v-list-item-action>
                            <v-icon :color="editBenefitObject.applicable_list.length > 0 ? 'indigo darken-4' : ''">
                              {{ iconEditDoc }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ selectAllDocText }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 5">
                              <span>{{ item.doctor_name }}</span>
                          </v-chip>
                          <span
                              v-if="index === 5"
                              class="grey--text text-caption"
                              >
                              (+{{ editBenefitObject.applicable_list.length - 5 }} others)
                          </span>
                      </template>
                    </v-select>
                    <v-select
                      solo
                      multiple
                      v-else
                      v-model="editBenefitObject.applicable_list"
                      :items="listOfSpecializationAvailable"
                      item-text="specialization_name"
                      item-value="_id"
                      return-object
                      :disabled="!editBenefitObject.package_type"
                      >
                      <template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @click="toggleEditSpecialization"
                          >
                          <v-list-item-action>
                            <v-icon :color="editBenefitObject.applicable_list.length > 0 ? 'indigo darken-4' : ''">
                              {{ iconEditSpec }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ selectAllSpecText }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:selection="{ item, index }">
                          <v-chip v-if="index < 5">
                              <span>{{ item.specialization_name }}</span>
                          </v-chip>
                          <span
                              v-if="index === 5"
                              class="grey--text text-caption"
                              >
                              (+{{ editBenefitObject.applicable_list.length - 5 }} others)
                          </span>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="benefitsIndividual">
                  <div>
                    <p>Promotion Type</p>
                  </div>
                  <div>
                    <v-select
                      solo
                      v-model="editBenefitObject.promotion_type"
                      :items="listOfPromotionTypes"
                      >
                    </v-select>
                  </div>
                </div>
                <div class="benefitsIndividual">
                  <div>
                    <p v-if="editBenefitObject.promotion_type=='FLAT_OFF'">Discount Amount</p>
                    <p v-else-if="editBenefitObject.promotion_type=='PERCENTAGE_OFF'">Discount</p>
                    <p v-else>Discount value</p>
                  </div>
                  <div>
                    <v-text-field
                      solo
                      v-model="editBenefitObject.discount"
                      :prefix = "editBenefitObject.promotion_type && editBenefitObject.promotion_type !='PERCENTAGE_OFF' ? 'MMK' : ''"
                      :suffix = "editBenefitObject.promotion_type && editBenefitObject.promotion_type =='PERCENTAGE_OFF' ? '%' : ''"
                      >
                    </v-text-field>
                  </div>
                </div>
              </div>
              <div class="benefitsRow2">
                <div class="benefitsIndividual">
                  <div>
                    <p>Count</p>
                  </div>
                  <div>
                    <v-text-field
                      solo
                      v-model="editBenefitObject.promotion_count"
                      style="width: 250px;"
                      >
                    </v-text-field>
                  </div>
                </div>
                <div class="benefitsIndividual">
                  <div>
                    <p>Minimum Spent Amount</p>
                  </div>
                  <div>
                    <v-text-field
                      solo
                      v-model="editBenefitObject.minimum_spend"
                      placeholder="0 if no minimum amount"
                      prefix="MMK"
                      >
                    </v-text-field>
                  </div>
                </div>
                <div v-if="editBenefitObject.promotion_type=='PERCENTAGE_OFF' && parseInt(editBenefitObject.discount) < 100" class="benefitsIndividual">
                  <div>
                    <p>Maximum Discount Amount</p>
                  </div>
                  <div>
                    <v-text-field
                    solo
                      v-model="editBenefitObject.max_discount"
                      placeholder="Blank if no limit"
                      prefix="MMK"
                      >
                    </v-text-field>
                  </div>
                </div>
              </div>
              <div class="benefitsRow2">
                <div class="FrequencyIndividual">
                  <p class="FrequencyTitle">Frequency</p>
                </div>
              </div>
              <div class="benefitsRow3">
                <div class="benefitsIndividualFrequency">
                  <div>
                    <p>Days</p>
                  </div>
                  <div>
                    <v-text-field
                      solo
                      v-model="editBenefitObject.frequency_days"
                      style="width: 250px;"
                      type="number"
                      :rules="frequency_days_rules"
                      :disabled="editBenefitObject.isFreqDisabled"
                      >
                    </v-text-field>
                  </div>
                </div>
                <div class="benefitsIndividualFrequency">
                  <div>
                    <p>Count</p>
                  </div>
                  <div>
                    <v-text-field
                      solo
                      v-model="editBenefitObject.frequency_count"
                      type="number"
                      :rules="frequency_count_rules"
                      :disabled="editBenefitObject.isFreqDisabled"
                      >
                    </v-text-field>
                  </div>
                </div>
              </div>

              <div style="display:flex; justify-content:flex-end;">
                <v-btn style="margin-bottom: 8px; margin-right: 8px; background-color: #1467BF;" class="primary" @click="editBenefit(editBenefitObject)" :disabled="isEditClicked || checkDisabled(editBenefitObject)" :loading="isEditClicked">
                  Save
                </v-btn>
              </div>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <!-- Edit benefit Dialog End -->

      <div>
        <v-row justify="center">
          <v-dialog v-model="FreeAppModel" persistent max-width="500">
            <v-card>
              <v-card-title class="createCorporateDilogTitle">
                <div>
                  <p style="font-weight:bold;margin-top:10px" >
                    WARNING!
                  </p>
                </div>
              </v-card-title>
              <div align="center">
                This might result in free appointment.<br> Proceed with caution. Click Save again.
              </div>
              <v-card-actions style="display:flex; justify-content:flex-end">
                <div>
                  <v-btn
                    color="error"
                    width="100px"
                    style="background-color: #1467BF;"
                    @click="closeWarningPopup"
                    >
                    Ok
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div>
        <v-row justify="center">
          <v-dialog v-model="verifyBenefitsPopup" persistent max-width="500">
            <v-card>
              <v-card-title class="createCorporateDilogTitle">
                <div>
                  <p style="font-weight:bold;margin-top:10px" >
                    WARNING!
                  </p>
                </div>
              </v-card-title>
              <div align="center">
                Benefits once created cannot be edited. <br> Please verify them carefully before creating the benefits.
              </div>
              <v-card-actions style="display:flex; justify-content:space-between">
                <div>
                  <v-btn
                    color="success"
                    width="100px"
                    style="background-color: #1467BF;"
                    @click="verifyBenefitsPopup = false"
                    >
                    Verify
                  </v-btn>
                </div>
                <div>
                  <v-btn
                    color="error"
                    width="100px"
                    style="background-color: #1467BF;"
                    @click="SaveAndContinue"
                    :disabled="continueDisable"
                    >
                    Continue
                  </v-btn>
                </div>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div style="background-color: #f5faff; height: 94.5vh !important">
        <v-row cols="12">
          <v-col cols="2">
            <SideBar style="max-height: 50px" :pageName="pageName"></SideBar>
          </v-col>
          <v-col cols="10">
            <div style="margin:40px">
              <v-row style="margin-left: 5px" class="pageHeaderClass">
                <div>
                  <span style="font-weight: 800; font-size: 12pt; text-transform: uppercase; letter-spacing: 1px;">
                    <h3>Create Benefits</h3>
                  </span>
                </div>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="packageBenefits"
                class="editBenefitTable"
                >
                <template v-slot:item.action="{item}" >
                  <v-btn class="mx-2" icon @click="editBenefitPopup(item)">
                    <img src="https://s3iconimages.mymedicine.com.mm/edit.svg" />
                  </v-btn>
                  <v-btn :disabled ="isDeleteDisabled" class="mx-2" icon @click="deleteBenefit(item)">
                    <img src="https://s3iconimages.mymedicine.com.mm/family_delete.svg" style="height: 24px; width: 24px;"/>
                  </v-btn>
                </template>
              </v-data-table>
              <div>
                <div v-for="(item,index2) in benefitsObject" :key=index2>
                  <v-card>
                    <div class="closeBtnDiv" v-if="benefitsObject.length > 1">
                      <v-btn  icon @click="deleteBenefitRow(item.package_type,item.applicable_list, item.promotion_type, item.discount, item.promotion_count, item.minimum_spend, item.max_discount)">
                        <img src="@/assets/popupClose.svg" >
                      </v-btn>
                    </div>
                    <div class="benefitsRow">
                      <div class="benefitsIndividual">
                        <div>
                          <p>Package Scope</p>
                        </div>
                        <div>
                          <v-select
                            solo
                            v-model="item.package_type"
                            :items="listOfPackageScope"
                            style="width: 250px;"
                            >
                          </v-select>
                        </div>
                      </div>
                      <div class="benefitsIndividual">
                        <div>
                          <p>Applicable On</p>
                        </div>
                        <div>
                          <v-select
                            solo
                            multiple
                            v-model="item.applicable_list"
                            return-object
                            :disabled="!item.package_type"
                            v-if="item.package_type == 'Doctors'"
                            :items="listOfDoctorsAvailable"
                            item-text="doctor_name"
                            item-value="_id"
                            >
                            <template v-slot:prepend-item>
                              <v-list-item
                                  @click="toggleCreateDoctor(item)"
                                  >
                                  <v-list-item-action>
                                      <v-icon :color="item.applicable_list.length > 0 ? 'indigo darken-4' : ''">
                                          {{ iconCreateDoc(item) }}
                                      </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                      <v-list-item-title>
                                          {{ selectAllCreateDocText(item) }}
                                      </v-list-item-title>
                                  </v-list-item-content>
                              </v-list-item>
                              <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{item, index}">
                              <v-chip v-if="index < 5">
                                {{ benefitsObject[index2]['applicable_list'][index].doctor_name}}
                              </v-chip>
                              <span v-if ="index === 5" class="grey--text text-caption">
                                (+ {{ benefitsObject[index2]['applicable_list'].length - 5 }} others)
                              </span>
                            </template>
                          </v-select>
                          <v-select
                            solo
                            multiple
                            v-model="item.applicable_list"
                            return-object
                            :disabled="!item.package_type"
                            v-else-if="item.package_type == 'Specializations'"
                            :items="listOfSpecializationAvailable"
                            item-text="specialization_name"
                            item-value="_id"
                            >
                            <template v-slot:prepend-item>
                              <v-list-item
                                  @click="toggleCreateSpecialization(item)"
                                  >
                                  <v-list-item-action>
                                      <v-icon :color="item.applicable_list.length > 0 ? 'indigo darken-4' : ''">
                                          {{ iconCreateSpec(item) }}
                                      </v-icon>
                                  </v-list-item-action>
                                  <v-list-item-content>
                                      <v-list-item-title>
                                          {{ selectAllCreateSpecText(item) }}
                                      </v-list-item-title>
                                  </v-list-item-content>
                              </v-list-item>
                              <v-divider class="mt-2"></v-divider>
                            </template>
                            <template v-slot:selection="{item, index}">
                              <v-chip v-if="index < 5">
                                {{ benefitsObject[index2]['applicable_list'][index].specialization_name}}
                              </v-chip>
                              <span v-if ="index === 5" class="grey--text text-caption">
                                (+ {{ benefitsObject[index2]['applicable_list'].length - 5 }} others)
                              </span>
                            </template>
                          </v-select>
                          <v-select
                            solo
                            multiple
                            v-else
                            v-model="item.applicable_list"
                            return-object
                            :disabled="!item.package_type"
                            :items="listOfSpecializationAvailable"
                            item-text="specialization_name"
                            item-value="_id"
                            >
                          </v-select>
                        </div>
                      </div>
                      <div class="benefitsIndividual">
                        <div>
                          <p>Promotion Type</p>
                        </div>
                        <div>
                          <v-select
                            solo
                            v-model="item.promotion_type"
                            :items="listOfPromotionTypes"
                            >
                          </v-select>
                        </div>
                      </div>
                      <div class="benefitsIndividual">
                        <div>
                          <p v-if="item.promotion_type=='FLAT_OFF'">Discount Amount</p>
                          <p v-else-if="item.promotion_type=='PERCENTAGE_OFF'">Discount</p>
                          <p v-else>Discount value</p>
                        </div>
                        <div>
                          <v-text-field
                            solo
                            v-model="item.discount"
                            :prefix = "item.promotion_type && item.promotion_type !='PERCENTAGE_OFF' ? 'MMK' : ''"
                            :suffix = "item.promotion_type && item.promotion_type =='PERCENTAGE_OFF' ? '%' : ''"
                            >
                          </v-text-field>
                        </div>
                      </div>
                    </div>
                    <div class="benefitsRow2">
                      <div class="benefitsIndividual">
                        <div>
                          <p>Count</p>
                        </div>
                        <div>
                          <v-text-field
                            solo
                            v-model="item.promotion_count"
                            style="width: 250px;"
                            >
                          </v-text-field>
                        </div>
                      </div>
                      <div class="benefitsIndividual">
                        <div>
                          <p>Minimum Spent Amount</p>
                        </div>
                        <div>
                          <v-text-field
                            solo
                            v-model="item.minimum_spend"
                            placeholder="0 if no minimum spend limit"
                            prefix="MMK"
                            >
                          </v-text-field>
                        </div>
                      </div>
                      <div v-if="item.promotion_type=='PERCENTAGE_OFF' && parseInt(item.discount) < 100" class="benefitsIndividual">
                        <div>
                          <p>Maximum Discount Amount</p>
                        </div>
                        <div>
                          <v-text-field
                            solo
                            v-model="item.max_discount"
                            placeholder="Blank if no limit"
                            prefix="MMK"
                            >
                          </v-text-field>
                        </div>
                      </div>
                    </div>
                    <div class="benefitsRow2">
                      <div class="FrequencyIndividual">
                        <p class="FrequencyTitle">Frequency</p>
                      </div>
                    </div>
                    <div class="benefitsRow3">
                      <div class="benefitsIndividualFrequency">
                        <div>
                          <p>Days</p>
                        </div>
                        <div>
                          <v-text-field
                            solo
                            v-model="item.frequency_days"
                            style="width: 250px;"
                            type="number"
                            :rules="frequency_days_rules"
                            >
                          </v-text-field>
                        </div>
                      </div>
                      <div class="benefitsIndividualFrequency">
                        <div>
                          <p>Count</p>
                        </div>
                        <div>
                          <v-text-field
                            solo
                            v-model="item.frequency_count"
                            type="number"
                            :rules="frequency_count_rules"
                            >
                          </v-text-field>
                        </div>
                      </div>
                    </div>
                  </v-card>
                  <div class="vbtnClass">
                    <v-btn
                      :disabled="isSaveClicked || checkDisabled(item)"
                      @click="saveAndAddMore(item)"
                      :loading="isSaveClicked"
                      style="background-color: #1467BF;"
                      class="primary">
                      Save and add more
                    </v-btn>
                    <v-btn
                      :disabled = "isSaveClicked"
                      :loading = "isSaveClicked"
                      style="background-color: #1467BF;"
                      @click="redirectToAddMemberLink(item)"
                      class="primary"
                      >
                      Continue
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </div>
    </v-app>
  </div>
</template>
<script>
  import axios from "axios";
  import NavBar from "../../../views/navbar.vue";
  import SideBar from "../../../views/sideBar.vue";
  import {handleError} from "../../../utils/utils";

  export default{
    name:"AddBenefitsToPackage",
    data(){
      return{
        pageName: "",
        currentUser: "",
        currentUserName: "",
        benefitsObject:[{"package_type":'',
          "applicable_list":'',
          "promotion_type":'',
          "discount":'',
          "promotion_count":'',
          "minimum_spend":'',
          "max_discount":''}],
        listOfPackageScope:["Specializations","Doctors"],
        listOfDoctorsAvailable:[],
        listOfSpecializationAvailable:[],
        listOfPromotionTypes:['PERCENTAGE_OFF', 'FLAT_OFF'],
        createdBy:"",
        packageID:"",
        packageBenefits: [],
        headers:[
        {
          text: 'Type',
          align: 'start',
          sortable: false,
          value: 'package_type',
        },
        { text: 'List', value: 'applicable_list_str',  width: "20%", class: 'listClass', cellClass:'listClass'},
        { text: 'Promotion Type', value: 'promotion_type' },
        { text: 'Discount Amount', value: 'discount' },
        { text: 'Count', value: 'promotion_count' },
        { text: 'Min Spent Amount', value: 'minimum_spend' },
        { text: 'Max Discount', value: 'max_discount' },
        { text: 'Frequency-Days', value: 'frequency_days' },
        { text: 'Frequency-Count', value: 'frequency_count' },
        { text: 'Action', value: 'action' },
        ],
        isSaveClicked: false,
        isDeleteDisabled: false,
        alertFreeWarn: false,
        FreeAppModel: false,
        editBenefitModal : false,
        editBenefitObject : {},
        isEditClicked: false,
        frequency_days_rules: [(v) => (!v || parseInt(v) > 0) || "Days cannot be negative"],
        frequency_count_rules: [(v) => (!v || parseInt(v) > 0) || "Count cannot be negative"],
        verifyBenefitsPopup: false,
        currentItem: {},
        continueDisable: false,
      }
    },
    components: {
      NavBar,
      SideBar,
    },
    mounted()
    {
      this.pageName = "AddBenefitsToPackage";
      this.currentUser = this.$cookies.get("token");
      if (!this.currentUser) {
        this.$router.push({
          name: "AdminLogin",
        });
      } else {
        var params = this.$route.params.package_id;
        this.packageID = params
        var getAdminBody ={
          token : this.currentUser,
          typeOfUser : "ADMIN",
          adminType : "ADMIN"
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/getAdmin",getAdminBody)
        .then((getAdminResponse)=>{
          this.createdBy = getAdminResponse.data.data._id

          var readCorporatePackageByIdBody = {
            corporatePackageId : this.packageID,
            token : this.currentUser,
            typeOfUser : "ADMIN"
          }
          axios.post(process.env.VUE_APP_BACKEND_URL + "/readCorporatePackageById", readCorporatePackageByIdBody)
          .then((readCorporatePackageByIdResponse) => {
            readCorporatePackageByIdResponse.data.data.benefits.map((benefit) => {
              if(!benefit.frequency) {
                this.packageBenefits.push({
                  'package_type': benefit.package_type,
                  'applicable_list_str': benefit.applicable_list_str.join(', '),
                  'promotion_type': benefit.promotion_type,
                  'discount': benefit.discount,
                  'promotion_count': benefit.promotion_count,
                  'minimum_spend': benefit.minimum_spend,
                  'max_discount': benefit.max_discount,
                  'isDeleteDisabled': false,
                  'id': benefit.id
                });
              } else {
                this.packageBenefits.push({
                  'package_type': benefit.package_type,
                  'applicable_list_str': benefit.applicable_list_str.join(', '),
                  'promotion_type': benefit.promotion_type,
                  'discount': benefit.discount,
                  'promotion_count': benefit.promotion_count,
                  'minimum_spend': benefit.minimum_spend,
                  'max_discount': benefit.max_discount,
                  'isDeleteDisabled': false,
                  'frequency_days': benefit.frequency.number_of_days,
                  'frequency_count': benefit.frequency.booking_count,
                  'id': benefit.id
                });
              }
            });

                    // Get Valid Applicable List.
            let getValidApplicableListBody = {
              token : this.currentUser,
              typeOfUser : "ADMIN",
              corporatePackageId : this.packageID
            };

            axios.post(process.env.VUE_APP_BACKEND_URL + "/getValidApplicableList", getValidApplicableListBody)
            .then((getValidApplicableListResponse) => {
              let applicableDoctors = getValidApplicableListResponse.data.applicable_doctors;
              let applicableSpecializations = getValidApplicableListResponse.data.applicable_specializations;

              this.listOfSpecializationAvailable = applicableSpecializations;
              this.listOfDoctorsAvailable = applicableDoctors;

            })
            .catch((getValidApplicableListError) => {
              handleError(getValidApplicableListError, this.$router, 'getValidApplicableListError', 'AdminLogin', 'token');
            });
          })
          .catch((readCorporatePackageByIdError) => {
            handleError(readCorporatePackageByIdError, this.$router, 'readCorporatePackageByIdError', 'AdminLogin', 'token')
          })
        })
        .catch((getAdminError)=>{
          if (getAdminError.response.status == 401) {
            window.alert("Sorry !! You are unauthorized !!");
            this.$router.push({
              name: "AdminLogin",
            });
          } else {
            window.alert("Something went wrong...");
          }
        });
      }
    },
    computed: {
      //edit Spec Popup
      selectEditAllSpecDisable () {
        return this.editBenefitObject.applicable_list.length === this.listOfSpecializationAvailable.length;
      },
      selectEditParticularSpecialization () {
        return this.editBenefitObject.applicable_list.length > 0;
      },
      iconEditSpec () {
        if (this.selectEditAllSpecDisable) return 'mdi-close-box';
        if (this.selectEditParticularSpecialization) return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
      },
      selectAllSpecText() {
        return this.editBenefitObject.applicable_list.length === this.listOfSpecializationAvailable.length ? 'Unselect All' : 'Select All'
      },
      //edit Doc Popup
      selectEditAllDocDisable () {
        return this.editBenefitObject.applicable_list.length === this.listOfDoctorsAvailable.length;
      },
      selectEditParticularDoctor () {
        return this.editBenefitObject.applicable_list.length > 0;
      },
      iconEditDoc () {
        if (this.selectEditAllDocDisable) return 'mdi-close-box';
        if (this.selectEditParticularDoctor) return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
      },
      selectAllDocText() {
        return this.editBenefitObject.applicable_list.length === this.listOfDoctorsAvailable.length ? 'Unselect All' : 'Select All'
      },
    },
    methods:{
      //Create Spec module
      selectAllCreateSpecText(item) {
        return item.applicable_list.length === this.listOfSpecializationAvailable.length ? 'Unselect All' : 'Select All';
      },
      selectCreateAllSpecDisable (item) {
        return item.applicable_list.length === this.listOfSpecializationAvailable.length;
      },
      selectCreateParticularSpecialization (item) {
        return item.applicable_list.length > 0;
      },
      iconCreateSpec (item) {
        if (this.selectCreateAllSpecDisable(item)) return 'mdi-close-box';
        if (this.selectCreateParticularSpecialization(item)) return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
      },
      toggleEditSpecialization() {
        if (this.selectEditAllSpecDisable) {
            this.editBenefitObject.applicable_list = [];
        } else {
            this.editBenefitObject.applicable_list = this.listOfSpecializationAvailable.slice();
        }
      },
      toggleCreateSpecialization(item) {
        if (this.selectCreateAllSpecDisable(item)) {
            item.applicable_list = [];
        } else {
            item.applicable_list = this.listOfSpecializationAvailable.slice();
        }
      },
      //Create Docu module
      selectAllCreateDocText(item) {
        return item.applicable_list.length === this.listOfDoctorsAvailable.length ? 'Unselect All' : 'Select All';
      },
      selectCreateAllDocDisable (item) {
        return item.applicable_list.length === this.listOfDoctorsAvailable.length;
      },
      selectCreateParticularDoctor (item) {
        return item.applicable_list.length > 0;
      },
      iconCreateDoc (item) {
        if (this.selectCreateAllDocDisable(item)) return 'mdi-close-box';
        if (this.selectCreateParticularDoctor(item)) return 'mdi-minus-box';
        return 'mdi-checkbox-blank-outline';
      },
      toggleEditDoctor() {
        if (this.selectEditAllDocDisable) {
            this.editBenefitObject.applicable_list = [];
        } else {
            this.editBenefitObject.applicable_list = this.listOfDoctorsAvailable.slice();
        }
      },
      toggleCreateDoctor(item) {
        if (this.selectCreateAllDocDisable(item)) {
            item.applicable_list = [];
        } else {
            item.applicable_list = this.listOfDoctorsAvailable.slice();
        }
      },
      checkDisabled(editBenefitObject)
      {
          return !(editBenefitObject.promotion_type && ( editBenefitObject.promotion_count && parseInt(editBenefitObject.promotion_count) > 0 ) && (editBenefitObject.applicable_list && editBenefitObject.applicable_list.length > 0 ) && editBenefitObject.package_type && (editBenefitObject.discount && parseInt(editBenefitObject.discount) > 0) && ( (editBenefitObject.promotion_type == 'FLAT_OFF' && parseInt(editBenefitObject.minimum_spend) >= 0 ) || editBenefitObject.promotion_type == 'PERCENTAGE_OFF' && parseInt(editBenefitObject.discount) <= 100 && parseInt(editBenefitObject.minimum_spend) >= 0 && ( ( !editBenefitObject.max_discount && parseInt(editBenefitObject.max_discount) != 0 ) || parseInt(editBenefitObject.max_discount) > 0 ) ) && ( (editBenefitObject.frequency_count && editBenefitObject.frequency_days && editBenefitObject.frequency_count > 0 && editBenefitObject.frequency_days > 0 && editBenefitObject.frequency_count <= parseInt(editBenefitObject.promotion_count) ) || (!editBenefitObject.frequency_count && !editBenefitObject.frequency_days) ) );
      },
      SaveAndContinue()
      {
        this.saveAndAddMore(this.currentItem,'SaveAndContinue');
      },
      closeWarningPopup()
      {
        this.alertFreeWarn = true;
        this.FreeAppModel = false;
        this.isSaveClicked = false;
      },
      editBenefitPopupClose(){
        this.editBenefitObject.applicable_list = [];

        this.editBenefitModal = false;
      },
      editBenefitPopup(item){
            // console.log("item : ", item);
        this.editBenefitObject = {
          id : item.id,
          package_type : item.package_type,
          applicable_list : [],
          promotion_type : item.promotion_type,
          discount : item.discount,
          max_discount : (item.promotion_type == 'PERCENTAGE_OFF' && item.discount == '100') ? '' : ( item.max_discount ? item.max_discount : '') ,
          promotion_count : item.promotion_count,
          minimum_spend : item.minimum_spend,
          frequency_count: item.frequency_count ? item.frequency_count : null,
          frequency_days: item.frequency_days ? item.frequency_days : null,
          isFreqDisabled: item.frequency_count && item.frequency_days ? true : false,
          status : item.status
        };
            // console.log("editBenefitObject : ", this.editBenefitObject);

        let editBenefitApplicableListBody = {
          corporatePackageId : this.packageID,
          benefitId : item.id,
          token : this.currentUser,
          typeOfUser : "ADMIN",
        };

            // console.log("editBenefitApplicableListBody :", editBenefitApplicableListBody);

        axios.post(process.env.VUE_APP_BACKEND_URL + "/editBenefitApplicableList", editBenefitApplicableListBody)
        .then((editBenefitApplicableListResponse) => {
                // console.log("editBenefitApplicableListResponse : ", editBenefitApplicableListResponse);
          let applicableSpecializations = editBenefitApplicableListResponse.data.applicable_specializations;
          let applicableDoctors = editBenefitApplicableListResponse.data.applicable_doctors;

          this.listOfSpecializationAvailable = applicableSpecializations.map((ele) => {
            return {
              _id : ele._id,
              specialization_name : ele.specialization_name,
              customDisabled : false
            }
          });
          this.listOfDoctorsAvailable = applicableDoctors.map((ele) => {
            return {
              _id : ele._id,
              doctor_name : ele.doctor_name,
              customDisabled : false
            }
          });
          this.editBenefitModal = true;
        })
        .catch((editBenefitApplicableListError) => {
          handleError(editBenefitApplicableListError, this.$router, 'editBenefitApplicableListError', 'AdminLogin', 'token');
        });
      },
      editBenefit(benefitObject){
        this.isEditClicked = true;
        let editBenefitOfCorporatePackageBody = {
          corporatePackageId : this.packageID,
          benefit : JSON.stringify({
            id : benefitObject.id,
            package_type : benefitObject.package_type,
            applicable_list : benefitObject.applicable_list.map((ele) => {return ele._id}),
            promotion_type : benefitObject.promotion_type,
            discount : benefitObject.discount,
            max_discount : (benefitObject.promotion_type == 'PERCENTAGE_OFF' && benefitObject.discount == '100') ? '' : ( benefitObject.max_discount ? benefitObject.max_discount : ''),
            minimum_spend : benefitObject.minimum_spend,
            promotion_count : benefitObject.promotion_count,
            frequency_days: benefitObject.frequency_days,
            frequency_count: benefitObject.frequency_count
          }),
          updatedBy : this.createdBy,
          token : this.currentUser,
          typeOfUser : "ADMIN",
        };
        if(!benefitObject.frequency_count || (benefitObject.frequency_count <= parseInt(benefitObject.promotion_count)) )
        {
          axios.post(process.env.VUE_APP_BACKEND_URL + "/editBenefitOfCorporatePackage", editBenefitOfCorporatePackageBody)
          .then((editBenefitOfCorporatePackageResponse) => {
            this.isEditClicked = false;
                  // console.log("editBenefitOfCorporatePackageResponse : ", editBenefitOfCorporatePackageResponse);
            window.alert("Benefit edited successfully!");
            let index = this.packageBenefits.findIndex((ele) => {
              if(ele.id == benefitObject.id){
                return true;
              }
              else{
                return false;
              }
            });

            let editedBenefitObject = {
              'package_type': benefitObject.package_type,
              'applicable_list_str': (benefitObject.applicable_list.map((ele) => {
                if(!ele.specialization_name){
                  return ele.doctor_name
                }
                else if(!ele.doctor_name){
                  return ele.specialization_name
                }
              })).join(', '),
              'promotion_type': benefitObject.promotion_type,
              'discount': String(parseInt(benefitObject.discount)),
              'promotion_count': String(parseInt(benefitObject.promotion_count)),
              'minimum_spend': String(parseInt(benefitObject.minimum_spend)),
              'max_discount': String(parseInt(benefitObject.max_discount)) ? benefitObject.max_discount : '',
              'frequency_count': benefitObject.frequency_count,
              'frequency_days': benefitObject.frequency_days,
              'isDeleteDisabled': false,
              'id': benefitObject.id
            };
            this.packageBenefits.splice(index, 1, editedBenefitObject);

            let getValidApplicableListBody = {
              token : this.currentUser,
              typeOfUser : "ADMIN",
              corporatePackageId : this.packageID
            };

            axios.post(process.env.VUE_APP_BACKEND_URL + "/getValidApplicableList", getValidApplicableListBody)
            .then((getValidApplicableListResponse) => {
              let applicableDoctors = getValidApplicableListResponse.data.applicable_doctors;
              let applicableSpecializations = getValidApplicableListResponse.data.applicable_specializations;

              this.listOfSpecializationAvailable = applicableSpecializations;
              this.listOfDoctorsAvailable = applicableDoctors;
            })
            .catch((getValidApplicableListError) => {
                handleError(getValidApplicableListError, this.$router, 'getValidApplicableListError', 'AdminLogin', 'token');
            });

            this.editBenefitModal = false;
          })
          .catch((editBenefitOfCorporatePackageError) => {
            this.isEditClicked = false;
            handleError(editBenefitOfCorporatePackageError, this.$router, 'editBenefitOfCorporatePackageError', 'AdminLogin', 'token');
          });
        }
        else
        {
          window.alert('Frequency count cannot be greater than issue count');
          this.isEditClicked = false;
        }
      },
      deleteBenefit(item){
        this.isDeleteDisabled = true;
        var removeBenefitFromCorporatePackageBody = {
          corporatePackageId:this.packageID,
          benefitId:item.id,
          updatedBy: this.createdBy,
          token : this.currentUser,
          typeOfUser : "ADMIN",
        }
        axios.post(process.env.VUE_APP_BACKEND_URL+"/removeBenefitFromCorporatePackage",removeBenefitFromCorporatePackageBody)
        .then((removeBenefitFromCorporatePackageResponse)=>{
          window.alert("Benefit removed");
          this.isDeleteDisabled = false;
          this.packageBenefits = this.packageBenefits.filter((x) => {
            return x.id != item.id
          });

          let getValidApplicableListBody = {
            token : this.currentUser,
            typeOfUser : "ADMIN",
            corporatePackageId : this.packageID
          };

          axios.post(process.env.VUE_APP_BACKEND_URL + "/getValidApplicableList", getValidApplicableListBody)
          .then((getValidApplicableListResponse) => {
            let applicableDoctors = getValidApplicableListResponse.data.applicable_doctors;
            let applicableSpecializations = getValidApplicableListResponse.data.applicable_specializations;

            this.listOfSpecializationAvailable = applicableSpecializations;
            this.listOfDoctorsAvailable = applicableDoctors;
          })
          .catch((getValidApplicableListError) => {
            handleError(getValidApplicableListError, this.$router, 'getValidApplicableListError', 'AdminLogin', 'token');
          });
        })
        .catch((removeBenefitFromCorporatePackageError)=>{
          console.log("removeBenefitFromCorporatePackageError",removeBenefitFromCorporatePackageError);
          window.alert("Something went wrong while removing benefit");
          this.isDeleteDisabled = false;
        });
      },
      addBenefitRow(){
        let getValidApplicableListBody = {
          token : this.currentUser,
          typeOfUser : "ADMIN",
          corporatePackageId : this.packageID
        };
        axios.post(process.env.VUE_APP_BACKEND_URL + "/getValidApplicableList", getValidApplicableListBody)
        .then((getValidApplicableListResponse) => {
          let applicableDoctors = getValidApplicableListResponse.data.applicable_doctors;
          let applicableSpecializations = getValidApplicableListResponse.data.applicable_specializations;

          this.listOfSpecializationAvailable = applicableSpecializations;
          this.listOfDoctorsAvailable = applicableDoctors;

          this.benefitsObject.push(
          {
            "package_type" : '',
            "applicable_list":'',
            "promotion_type":'',
            "discount":'',
            "promotion_count":'',
            "minimum_spend":'',
            "max_discount":''
          }
          );
        })
        .catch((getValidApplicableListError) => {
            handleError(getValidApplicableListError, this.$router, 'getValidApplicableListError', 'AdminLogin', 'token');
        });
        this.benefitsObject.push({"package_type":'',
          "applicable_list":'',
          "promotion_type":'',
          "discount":'',
          "promotion_count":'',
          "minimum_spend":'',
          "max_discount":''
        });
      },
      deleteBenefitRow(package_type, applicable_list, promotion_type, discount, promotion_count, minimum_spend, max_discount ) {
        this.benefitsObject.forEach(function (item, index, object) {
          if (item.package_type === package_type && item.applicable_list === applicable_list && item.promotion_type === promotion_type && item.discount === discount && item.promotion_count === promotion_count && item.minimum_spend === minimum_spend && item.max_discount === max_discount)
          {
            object.splice(index, 1);
          }
        });
      },
      redirectToAddMemberLink(item){
        if(!this.checkDisabled(item)) {
          this.currentItem = item;
          this.verifyBenefitsPopup = true;
        } else {
          this.$router.push({
            path: "/admin/CorporateMemberList/"+this.packageID
          });
        }
      },
      saveAndAddMore(item, source='saveAndAddMore'){
        this.isSaveClicked = true;
        item.minimum_spend = item.minimum_spend ? item.minimum_spend : "0";
        item.max_discount = (item.promotion_type == 'PERCENTAGE_OFF' && item.discount == '100') ? '' : ( item.max_discount ? item.max_discount : '');
        item.applicable_list_str = [];
        item.applicable_list.forEach((x) => {
          item.applicable_list_str.push(
            x.specialization_name ? x.specialization_name : x.doctor_name );
        });
        let appList = [];
        item.applicable_list.forEach((x) => {
          appList.push(x._id);
        });

        // Don't even try to understand, using all variables as is, except for applicable list for display purpose
        let new_var = {};
        new_var['package_type'] = item.package_type;
        new_var['applicable_list'] = appList;
        new_var['promotion_type'] = item.promotion_type;
        new_var['discount'] = item.discount;
        new_var['promotion_count'] = item.promotion_count;
        new_var['minimum_spend'] = item.minimum_spend;
        new_var['max_discount'] = item.max_discount;
        new_var['frequency_days'] = item.frequency_days;
        new_var['frequency_count'] = item.frequency_count;
        new_var['applicable_list_str'] = item.applicable_list_str;

        var addBenefitDetailsBody={
          corporatePackageId: this.packageID,
          benefit: JSON.stringify(new_var),
          updatedBy: this.createdBy,
          token : this.currentUser,
          typeOfUser : "ADMIN",
        };

        if(!item.frequency_count || (item.frequency_count <= parseInt(item.promotion_count) ) )
        {
          if(this.alertFreeWarn ==  false && ((item.promotion_type == 'FLAT_OFF' && parseInt(item.discount) > parseInt(item.minimum_spend)) || (item.promotion_type == 'PERCENTAGE_OFF' && parseInt(item.discount) == 100) ))
          {
            this.FreeAppModel = true;
          }
          else
          {
            this.continueDisable = true;
            axios.post(process.env.VUE_APP_BACKEND_URL + "/addBenefitToCorporatePackage", addBenefitDetailsBody)
            .then((addListOfBenefitsResponse)=>{
              this.continueDisable = false;
              this.packageBenefits.push({
                'package_type': item.package_type,
                'applicable_list_str': item.applicable_list_str.join(', '),
                'promotion_type': item.promotion_type,
                'discount': String(parseInt(item.discount)),
                'promotion_count': String(parseInt(item.promotion_count)),
                'minimum_spend': String(parseInt(item.minimum_spend)),
                'max_discount': String(parseInt(item.max_discount)) ? item.max_discount : '',
                'isDeleteDisabled': false,
                'id': addListOfBenefitsResponse.data.data.benefits.slice(-1)[0].id,
                'frequency_days': item.frequency_days,
                'frequency_count': item.frequency_count,
              });
              var getAllList={
                corporatePackageId: this.packageID,
                token : this.currentUser,
                typeOfUser : "ADMIN",
              }
              if(source == 'saveAndAddMore')
              {
                axios.post(process.env.VUE_APP_BACKEND_URL + "/getValidApplicableList", getAllList)
                .then((applicable_list_response) =>{
                  this.listOfDoctorsAvailable = applicable_list_response.data.applicable_doctors;
                  this.listOfSpecializationAvailable = applicable_list_response.data.applicable_specializations;
                  this.benefitsObject = [];
                  this.benefitsObject.push({"package_type":'',
                    "applicable_list":'',
                    "promotion_type":'',
                    "discount":'',
                    "promotion_count":'',
                    "minimum_spend":'',
                    "max_discount":''
                  })
                  this.isSaveClicked = false;
                })
                .catch((applicable_list_error) =>{
                  handleError(getValidApplicableListError, this.$router, 'getValidApplicableListError', 'AdminLogin', 'token');
                });
              }
              else
              {
                this.$router.push({
                  path: "/admin/CorporateMemberList/"+this.packageID
                });
              }
            })
            .catch((addListOfBenefitsError)=>{
              this.continueDisable = false;
              handleError(addListOfBenefitsError, this.$router, 'addListOfBenefitsError', 'AdminLogin', 'token');
            });
            this.alertFreeWarn = false;
          }
        }
        else
        {
          window.alert('Frequency count cannot be greater than issue count');
          this.isSaveClicked = false;
        }
      }
    }
  }

</script>
<style scoped>
  .FrequencyIndividual{
    margin: 0px 18px;
  }
  .FrequencyTitle{
    font-weight: 700;
    line-height: 19px;
  }
  .benefitsRow{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0px 18px;
    margin-bottom: 0px;
  }
  .benefitsRow2{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin:  0px 18px;
  }
  .benefitsRow3{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0px 18px;
  }
  .benefitsIndividual{
    display: flex;
    flex-direction: column;
    margin: 18px;
    text-align: left;
  }
  .benefitsIndividualFrequency{
    display: flex;
    flex-direction: column;
    margin: 8px 18px;
    text-align: left;
  }
  .vbtnClass{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 18px;
  }
  .closeBtnDiv{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-right: 18px;
    margin-top: 10px;
  }
</style>
<style>
  .editBenefitTable .v-data-table-header th{
    background: #1467bf !important;
    color: white !important;
    font-size: 8pt !important;
    font-weight: 500 !important;
  }
  .editBenefitTable tbody td{
    font-size: 8pt !important;
    font-weight: 700 !important;
  }
  .editBenefitTable{
    margin-top: 30px;
  }
</style>